import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import type { TimestampAutomationCommand } from '../../../config/enums'
import timestampsAutomationSettingsStateUpdate from '../../../redux/dashboardInputs/actions/timestampsAutomationSettingsState'
import type { TimestampProps } from '../../../redux/dashboardInputs/interfaces/TimestampProps'
import type { TimestampsAutomationState } from '../../../redux/dashboardInputs/interfaces/TimestampsAutomation'
import type { StateTree } from '../../../redux/interfaces'
import { useAppDispatch, useAppSelector } from '..'

export const minTimeNumber = 0
export const maxMinuteSecondNumber = 59

type UseTimestampAutomationSettings = [
	TimestampsAutomationState,
	(timestampCommand: TimestampAutomationCommand) => (event: ChangeEvent<HTMLInputElement>) => void,
	(props: Record<TimestampAutomationCommand, TimestampProps>) => void,
]

export const useTimestampAutomationSettings = (): UseTimestampAutomationSettings => {
	const { timestampsAutomationSettings, isValid } = useAppSelector(
		({
			dashboardInputs: {
				timestampsAutomationSettings: { value: timestampsAutomationSettings, isValid },
			},
		}: StateTree) => ({ timestampsAutomationSettings, isValid }),
	)

	const dispatch = useAppDispatch()
	const updateTimestampAutomationSettingsState = useCallback(
		(props: Record<TimestampAutomationCommand, TimestampProps>) => timestampsAutomationSettingsStateUpdate(props)(dispatch),
		[dispatch],
	)

	const onTimestampSettingChange = (timestampCommand: TimestampAutomationCommand) => (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value, name },
		} = event

		const numberValue = Number(value)

		const isValid = numberValue <= maxMinuteSecondNumber && numberValue >= minTimeNumber

		if (!isValid) return

		const updatedSetting = {
			...timestampsAutomationSettings[timestampCommand],
			[name]: numberValue,
		}

		const updatedSettings = { ...timestampsAutomationSettings, [timestampCommand]: updatedSetting }

		updateTimestampAutomationSettingsState(updatedSettings)
	}

	return [{ value: timestampsAutomationSettings, isValid }, onTimestampSettingChange, updateTimestampAutomationSettingsState]
}
