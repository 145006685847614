import { minSecondsBetweenStartShowAndDisplayWinners } from '../../config/const'
import { TimestampAutomationCommand } from '../../config/enums'
import type { TimestampProps } from '../../redux/dashboardInputs/interfaces/TimestampProps'

const orderedTimestamps = [
	TimestampAutomationCommand.StartShow,
	TimestampAutomationCommand.DisplayTop3,
	TimestampAutomationCommand.StartGame,
	TimestampAutomationCommand.PrepareCelebration,
	TimestampAutomationCommand.DisplayBeatTheGuest,
	TimestampAutomationCommand.PickWinners,
	TimestampAutomationCommand.StopShow,
]

const timestampPropsToSeconds = (timestampProperties: TimestampProps) => timestampProperties.minute * 60 + timestampProperties.second

export const validateShowTimestamps = (timestamps: Record<TimestampAutomationCommand, TimestampProps>) => {
	const timestampsSecondsValues = orderedTimestamps.map((timestampCommand) => timestampPropsToSeconds(timestamps[timestampCommand]))

	for (let i = 1; i < timestampsSecondsValues.length; i++) {
		if (timestampsSecondsValues[i] < timestampsSecondsValues[i - 1]) {
			return false
		}
	}

	const startShowTimestampInSeconds = timestampPropsToSeconds(timestamps[TimestampAutomationCommand.StartShow])
	const pickWinnersTimestampInSeconds = timestampPropsToSeconds(timestamps[TimestampAutomationCommand.PickWinners])

	return pickWinnersTimestampInSeconds - startShowTimestampInSeconds >= minSecondsBetweenStartShowAndDisplayWinners
}
