import type { DisplayWinnersActions } from '../interfaces/actions/DisplayWinnersActions'
import type { DisplayWinnersState } from '../interfaces/DisplayWinnersState'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const initialState: DisplayWinnersState = {
	isLoading: false,
	isAvailable: true,
}

export default function displayWinners(state = initialState, action: DisplayWinnersActions): DisplayWinnersState {
	switch (action.type) {
		case REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_START:
			return { ...state, isLoading: true }
		case REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_FAILURE:
			console.error('DISPLAY_WINNERS_FAILURE')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_SUCCESS:
			console.log('DISPLAY_WINNERS_SUCCESS')
			return { ...state, isLoading: false }
		case REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_TOGGLE:
			return { ...state, isAvailable: action.isAvailable }
		default:
			return state
	}
}
