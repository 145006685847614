import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import type { ChangeEvent, FunctionComponentElement, MouseEvent } from 'react'
import React, { useState } from 'react'
import { appEnv } from '../../config'
import { tooltipId } from '../../config/const'
import type { ShowType } from '../../config/enums'
import { CardButton } from '../../containers/Card'
import ExecuteAutomationButton from '../../containers/ExecuteAutomationButton/ExecuteAutomationButton'
import type { StateTree } from '../../redux/interfaces'
import type { EnableLeaderboardCutoff } from '../../redux/studioCommand/interfaces/EnableLeaderboardCutoff'
import type { PopulateLeaderboards } from '../../redux/studioCommand/interfaces/PopulateLeaderboards'
import type { UpdateArcadeAndPlayersSeason } from '../../redux/studioCommand/interfaces/UpdateArcadeAndPlayersSeason'
import { sendCommandToStudio } from '../../services/api/serverless'
import { getNextShowTime } from '../../utils/getNextShowTime'
import { useAppSelector } from '../../utils/hooks'
import { useLeaguesResetToggle } from '../../utils/hooks/commands/useLeaguesResetToggle'
import { useSeasonTransitionToggle } from '../../utils/hooks/commands/useSeasonTransitionToggle'
import { useShowType } from '../../utils/hooks/commands/useShowType'
import { useCommands } from '../../utils/hooks/useCommands'
import { useFeatureFlagsState } from '../../utils/hooks/useFeatureFlagsState'
import { useRollingRetention } from '../../utils/hooks/useRollingRetention'
import LiveStatus from '../LiveStatus/LiveStatus'
import ShowCalendar from '../ShowCalendar'
import ShowTypeSelect from '../ShowTypeSelect'
import Toggle from '../Toggle'
import { CommandsMenuContainer } from './CommandsMenuContainer'

dayjs.extend(utc)

function CommandsMenu(): FunctionComponentElement<typeof CommandsMenuContainer> {
	const {
		commands: state,
		sendWarmUp,
		sendStartCountdownAndShow: startCountdownAndShow,
		sendShowTopThree: showTopThree,
		sendStartGame: startGame,
		sendPrepareCelebration: prepareCelebration,
		sendDisplayBeatGuest: displayBeatGuest,
		sendPickWinners: pickWinners,
		sendDisplayWinners: displayWinners,
		sendResetChallenges: resetChallenges,
		sendStopShow: stopShow,
		toggleCommands,
		saveShowWithValidation: saveShow,
		getScheduledShow,
		overwriteDefaultValuesWithValidation: overwriteDefaultValues,
		sendPickSeasonWinner: pickSeasonWinner,
	} = useCommands()
	const [
		{
			data: { leagues: leaguesEnabled },
		},
	] = useFeatureFlagsState()
	const [isRetentionLoading, getRollingRetentionData] = useRollingRetention()
	const [leaguesReset, toggleLeaguesReset] = useLeaguesResetToggle()
	const [seasonTransition, toggleSeasonTransition] = useSeasonTransitionToggle()
	const [showTypeState, updateShowType] = useShowType()
	const [isEnablingLeaderboardCutoff, setIsEnablingLeaderboardCutoff] = useState<boolean>(false)
	const [isPopulatingLeaderboards, setIsPopulatingLeaderboards] = useState<boolean>(false)
	const [isUpdatingArcadeAndPlayersSeason, setIsUpdatingArcadeAndPlayersSeason] = useState<boolean>(false)
	const { isShowOn, isExecutionRunning, selectedDate } = useAppSelector(
		({
			commands: {
				startCountdownAndShow: { isAvailable: isStartShowAvailable },
				stopShow: { isAvailable: isStopShowAvailable },
			},
			executionShowInfo,
			wsMessage: { executeShowStatus },
			showCalendar: { selectedDate },
		}: StateTree) => ({
			isShowOn: !isStartShowAvailable && isStopShowAvailable,
			isExecutionRunning: executionShowInfo.status || executeShowStatus,
			selectedDate,
		}),
	)

	const resetPanel = (): void => {
		const { confirm: confirmDialog } = window
		const confirmMessage =
			'This will reset all the LiveOps buttons on the left panel so that they can be pressed again. Are you sure you want to proceed?'

		if (confirmDialog(confirmMessage)) {
			toggleCommands(true)
		}
	}

	const onEnableLeaderboardCutoffClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()
		setIsEnablingLeaderboardCutoff(true)

		try {
			const enableLeaderboardCutoffCommand: EnableLeaderboardCutoff = {
				command: 'enableLeaderboardCutoff',
			}

			await sendCommandToStudio(enableLeaderboardCutoffCommand)
			console.info('Leaderboard cutoff will be enabled!')
		} catch {
			console.error('Error enabling leaderboard cutoff')
		}
		setIsEnablingLeaderboardCutoff(false)
	}

	const onPopulateLeaderboardsClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()
		setIsPopulatingLeaderboards(true)

		try {
			const populateLeaderboardsCommand: PopulateLeaderboards = {
				command: 'populateLeaderboards',
			}

			await sendCommandToStudio(populateLeaderboardsCommand)
			console.info('Leaderboards will be updated!')
		} catch {
			console.error('Error populationg leaderboards')
		}
		setIsPopulatingLeaderboards(false)
	}

	const onUpdateArcadeAndPlayersSeasonClick = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
		event.stopPropagation()
		setIsUpdatingArcadeAndPlayersSeason(true)

		try {
			const { prompt: promptDialog, alert } = window
			const promptMessage = 'Please enter the season number you would like to place the Arcade and Players in this environment'
			const season = Number(promptDialog(promptMessage)?.trim())

			if (!Number.isInteger(season) || season <= 0) {
				alert('Please enter a valid integer number for the season input!')
				setIsUpdatingArcadeAndPlayersSeason(false)
				return
			}

			const populateLeaderboardsCommand: UpdateArcadeAndPlayersSeason = {
				command: 'updateArcadeAndPlayersSeason',
				season,
			}

			await sendCommandToStudio(populateLeaderboardsCommand)
			console.info('Arcade and Players season will be updated!')
		} catch {
			console.error('Error updating Arcade and Players season')
		}
		setIsPopulatingLeaderboards(false)
	}

	const nextShowTime = getNextShowTime()
	const selectedDateShowTime = getNextShowTime(selectedDate)
	const isAllowedToSaveShow = selectedDateShowTime >= nextShowTime
	const formattedShowTime = selectedDateShowTime.format('YYYY-MM-DD [at] HH:mm Z')

	const onChangeShowType = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			currentTarget: { value },
		} = event
		updateShowType(value as ShowType)
	}

	return (
		<>
			<CommandsMenuContainer>
				<ShowCalendar />

				<ShowTypeSelect showType={showTypeState.showType} onChangeShowType={onChangeShowType} />

				<CardButton
					disabled={state.saveScheduledShowInfo.isLoading || state.saveShowInfo.isLoading || !isAllowedToSaveShow}
					style={{ margin: '50px 0 30px 0' }}
					onClick={() => saveShow(nextShowTime.valueOf() === selectedDateShowTime.valueOf())}
					data-tooltip-content={`This will replace the currently saved values on the server for the Show that airs on ${formattedShowTime}, and the 24H Tournament that starts right after that`}
					data-tooltip-variant="warning"
					data-tooltip-id={tooltipId}
				>
					Save Show
				</CardButton>

				<CardButton
					onClick={getScheduledShow}
					data-tooltip-content={`This will load the currently saved values on the server for the Show that airs on ${formattedShowTime}, and the 24H Tournament that starts right after that`}
					data-tooltip-id={tooltipId}
				>
					Load Saved Show Data
				</CardButton>

				<LiveStatus isLive={isShowOn || isExecutionRunning} />

				<ExecuteAutomationButton />

				<CardButton onClick={sendWarmUp} disabled={state.warmUp.isLoading || isExecutionRunning}>
					Warm Up
				</CardButton>
				<CardButton
					onClick={startCountdownAndShow}
					isAvailable={state.startCountdownAndShow.isAvailable}
					disabled={state.startCountdownAndShow.isLoading || !state.startCountdownAndShow.isAvailable}
				>
					Start Show
				</CardButton>

				<CardButton
					onClick={showTopThree}
					isAvailable={state.showTopThree.isAvailable}
					disabled={state.showTopThree.isLoading || !state.showTopThree.isAvailable}
				>
					Display Top 3
				</CardButton>
				<CardButton
					onClick={startGame}
					isAvailable={state.startGame.isAvailable}
					disabled={state.startGame.isLoading || !state.startGame.isAvailable}
				>
					Start Game
				</CardButton>
				<CardButton
					onClick={prepareCelebration}
					isAvailable={state.prepareCelebration.isAvailable}
					disabled={state.prepareCelebration.isLoading || !state.prepareCelebration.isAvailable}
				>
					Prepare Celebration
				</CardButton>
				<CardButton
					onClick={displayBeatGuest}
					isAvailable={state.displayBeatGuest.isAvailable}
					disabled={state.displayBeatGuest.isLoading || !state.displayBeatGuest.isAvailable}
				>
					Display Beat the Guest
				</CardButton>

				<CardButton
					onClick={pickWinners}
					isAvailable={state.pickWinners.isAvailable}
					disabled={state.pickWinners.isLoading || !state.pickWinners.isAvailable}
				>
					Pick Winners
				</CardButton>

				<CardButton
					onClick={displayWinners}
					isAvailable={state.displayWinners.isAvailable}
					disabled={state.displayWinners.isLoading || !state.displayWinners.isAvailable}
				>
					Display Winners
				</CardButton>

				<CardButton
					onClick={resetChallenges}
					isAvailable={state.resetChallenges.isAvailable}
					disabled={state.resetChallenges.isLoading || !state.resetChallenges.isAvailable}
				>
					Reset Challenges
				</CardButton>

				<CardButton
					onClick={stopShow}
					isAvailable={state.stopShow.isAvailable}
					disabled={state.stopShow.isLoading || !state.stopShow.isAvailable}
				>
					Stop Show
				</CardButton>

				<CardButton
					style={{ margin: '50px 0' }}
					onClick={resetPanel}
					data-tooltip-content="This will reset all the LiveOps buttons on the left panel so that they can be pressed again"
					data-tooltip-id={tooltipId}
				>
					♲ Reset Buttons
				</CardButton>

				<CardButton disabled={state.overwriteDefaultValues.isLoading} style={{ margin: '0 0 50px 0' }} onClick={overwriteDefaultValues}>
					Overwrite default values
				</CardButton>

				<CardButton disabled={isRetentionLoading} style={{ margin: '0 0 50px 0' }} onClick={getRollingRetentionData}>
					Rolling Retention Data
				</CardButton>

				<CardButton
					onClick={pickSeasonWinner}
					isAvailable={state.pickSeasonWinner.isAvailable}
					disabled={state.pickSeasonWinner.isLoading || !state.pickSeasonWinner.isAvailable}
				>
					Pick Season Winner
				</CardButton>

				{leaguesEnabled && (
					<CardButton type="button" disabled={isEnablingLeaderboardCutoff} onClick={onEnableLeaderboardCutoffClick}>
						Enable Leaderboard Cutoff
					</CardButton>
				)}

				{appEnv !== 'prod' && (
					<CardButton type="button" disabled={isPopulatingLeaderboards} onClick={onPopulateLeaderboardsClick}>
						Populate Leaderboards
					</CardButton>
				)}

				{appEnv !== 'prod' && (
					<CardButton type="button" disabled={isUpdatingArcadeAndPlayersSeason} onClick={onUpdateArcadeAndPlayersSeasonClick}>
						Update Arcade and Players season
					</CardButton>
				)}

				<Toggle
					status={seasonTransition.seasonTransitionStatus}
					isDisabled={!seasonTransition.isAvailable}
					onChange={toggleSeasonTransition}
					label="Season transition"
				/>

				{leaguesEnabled && (
					<Toggle
						status={leaguesReset.leaguesResetStatus}
						isDisabled={!leaguesReset.isAvailable}
						onChange={toggleLeaguesReset}
						label="Leagues Reset"
					/>
				)}
			</CommandsMenuContainer>
		</>
	)
}

export default CommandsMenu
