import type { Dispatch } from 'redux'
import type { DisplayWinnersActions } from '../interfaces/actions/DisplayWinnersActions'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const displayDisplayWinnersToggle = (isAvailable: boolean): DisplayWinnersActions => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_TOGGLE, isAvailable }
}

export default function toggleDisplayWinners(isAvailable: boolean) {
	return (dispatch: Dispatch): void => {
		dispatch(displayDisplayWinnersToggle(isAvailable))
	}
}
