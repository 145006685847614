import { useBeatGuestValidation } from './useBeatGuestValidation'
import { useCountdownShowValidation } from './useCountdownShowValidation'
import { useGuestInfoValidation } from './useGuestInfoValidation'
import { usePickWinnersValidation } from './usePickWinnersValidation'
import { useTimestampsValidation } from './useTimestampsValidation'

export const useAllFieldsValidation = (): (() => Promise<boolean>) => {
	const validateBeatGuest = useBeatGuestValidation()
	const validateVideoGuestScore = useCountdownShowValidation()
	const validateGuestInfo = useGuestInfoValidation()
	const validatePickWinnersData = usePickWinnersValidation()
	const validateShowTimestamps = useTimestampsValidation()

	const validate = async (): Promise<boolean> => {
		const isBeatGuestValid = validateBeatGuest()
		const isPickWinnersDataValid = validatePickWinnersData()
		const showTimestampsValid = validateShowTimestamps()

		const promiseValidations = [validateVideoGuestScore(), validateGuestInfo()]

		const [isVideoGuestScoreValid, isGuestInfoValid] = await Promise.all(promiseValidations)

		return isBeatGuestValid && isPickWinnersDataValid && isVideoGuestScoreValid && isGuestInfoValid && showTimestampsValid
	}

	return validate
}
