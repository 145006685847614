import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { DisplayWinnersFailureAction } from '../interfaces/actions/DisplayWinnersFailureAction'
import type { DisplayWinnersStartAction } from '../interfaces/actions/DisplayWinnersStartAction'
import type { DisplayWinnersSuccessAction } from '../interfaces/actions/DisplayWinnersSuccessAction'
import type { DisplayWinners } from '../interfaces/DisplayWinners'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const displayWinnersStart = (): DisplayWinnersStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_START }
}

const displayWinnersFailure = (): DisplayWinnersFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_FAILURE }
}

const displayWinnersSuccess = (): DisplayWinnersSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.DISPLAY_WINNERS_SUCCESS }
}

export default function displayWinners() {
	return async (dispatch: Dispatch): Promise<void> => {
		dispatch(displayWinnersStart())

		try {
			const displayWinnersCommand: DisplayWinners = {
				command: 'displayWinners',
			}

			await sendCommandToStudio(displayWinnersCommand)
			dispatch(displayWinnersSuccess())
		} catch {
			dispatch(displayWinnersFailure())
		}
	}
}
