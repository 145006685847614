import { useCallback, useEffect } from 'react'
import type { StateTree } from '../../../redux/interfaces'
import displayWinners from '../../../redux/studioCommand/actions/displayWinners'
import toggleDisplayWinners from '../../../redux/studioCommand/actions/toggleDisplayWinners'
import { useAppDispatch, useAppSelector } from '..'
import type { PromiseVoid, ToggleCommand } from '../useCommands'

type UseDisplayWinners = [PromiseVoid, ToggleCommand]

export default function useDisplayWinners(): UseDisplayWinners {
	const dispatch = useAppDispatch()
	const isAvailable = useAppSelector(
		({
			wsMessage: {
				pickWinners: { isAvailable },
			},
		}: StateTree) => isAvailable,
	)
	const toggleDisplayWinnersButton = useCallback((isAvailable: boolean) => toggleDisplayWinners(isAvailable)(dispatch), [dispatch])
	const sendDisplayWinners = useCallback(() => displayWinners()(dispatch), [dispatch])

	useEffect(() => {
		toggleDisplayWinnersButton(isAvailable)
	}, [isAvailable, toggleDisplayWinnersButton])

	const triggerDisplayWinners = async (): Promise<void> => {
		await sendDisplayWinners()
	}

	return [triggerDisplayWinners, toggleDisplayWinnersButton]
}
